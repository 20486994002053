import HomeSVG from "../../../../assets/dashboard/home.svg";
import LessonSVG from "../../../../assets/dashboard/lesson.svg";
import AssignmentSVG from "../../../../assets/dashboard/assignment.svg";
import AssessmentSVG from "../../../../assets/dashboard/assessment.svg";
import ExaminationSVG from "../../../../assets/dashboard/examination.svg";
import ResultSVG from "../../../../assets/dashboard/result.svg";
import MessagesSVG from "../../../../assets/dashboard/messages.svg";
import { Link, useLocation } from "react-router-dom";
import Typography from "../../Typography";
import {
  ASSESSMENT_ROUTE,
  ASSIGNMENT_ROUTE,
  EXAMINATION_ROUTE,
  LESSONS_ROUTE,
  LESSON_ROUTE,
  MESSAGE_INBOX_ROUTE,
} from "../../../../routes";
import InlineSVG from "react-inlinesvg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { currentUser, userToken } from "../../../../features/login/authSlice";
function MenuIcons() {
  const location = useLocation();
  const [IS_DASHBOARD_ROUTE, setIS_DASHBOARD_ROUTE] = useState(true);
  const [IS_ASSESSMENT_ROUTE, setIS_ASSESSMENT_ROUTE] = useState(false);
  const [IS_ASSIGNMENT_ROUTE, setIS_ASSIGNMENT_ROUTE] = useState(false);
  const [IS_EXAMINATION_ROUTE, setIS_EXAMINATION_ROUTE] = useState(false);
  const [IS_LESSONS_ROUTE, setIS_LESSONS_ROUTE] = useState(false);
  const [IS_MESSAGE_INBOX_ROUTE, setIS_MESSAGE_INBOX_ROUTE] = useState(false);
  const [IS_RESULT_ROUTE, setIS_RESULT_ROUTE] = useState(false);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const user = useSelector(currentUser);
  const token = useSelector(userToken);

  useEffect(() => {
    const fetchAssessmentStatus = () => {
      axios
        .get(
          `https://api.online.hillcrosscollege.com/api/single/assessement/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const userAssessment = response.data.data[0];
          const completed = userAssessment.status === "completed";
          setAssessmentCompleted(completed);
        })
        .catch((error) => {});
    };

    fetchAssessmentStatus();

    const intervalId = setInterval(fetchAssessmentStatus, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setIS_DASHBOARD_ROUTE(true);
      setIS_ASSESSMENT_ROUTE(false);
      setIS_ASSIGNMENT_ROUTE(false);
      setIS_EXAMINATION_ROUTE(false);
      setIS_LESSONS_ROUTE(false);
    }
    if (
      location.pathname === ASSESSMENT_ROUTE ||
      location.pathname === ASSESSMENT_ROUTE + "/single" ||
      location.pathname === ASSESSMENT_ROUTE + "/pdf"
    ) {
      setIS_ASSESSMENT_ROUTE(true);
      setIS_DASHBOARD_ROUTE(false);
      setIS_ASSIGNMENT_ROUTE(false);
      setIS_EXAMINATION_ROUTE(false);
      setIS_LESSONS_ROUTE(false);
    }
    if (
      location.pathname === ASSIGNMENT_ROUTE ||
      location.pathname === ASSIGNMENT_ROUTE + "/single" ||
      location.pathname === ASSIGNMENT_ROUTE + "/pdf"
    ) {
      setIS_ASSIGNMENT_ROUTE(true);

      setIS_DASHBOARD_ROUTE(false);
      setIS_ASSESSMENT_ROUTE(false);
      setIS_EXAMINATION_ROUTE(false);
      setIS_LESSONS_ROUTE(false);
    }
    if (location.pathname === EXAMINATION_ROUTE) {
      setIS_EXAMINATION_ROUTE(true);

      setIS_DASHBOARD_ROUTE(false);
      setIS_ASSESSMENT_ROUTE(false);
      setIS_ASSIGNMENT_ROUTE(false);
      setIS_LESSONS_ROUTE(false);
    }
    if (location.pathname === LESSONS_ROUTE) {
      setIS_LESSONS_ROUTE(true);

      setIS_DASHBOARD_ROUTE(false);
      setIS_ASSESSMENT_ROUTE(false);
      setIS_ASSIGNMENT_ROUTE(false);
      setIS_EXAMINATION_ROUTE(false);
    }
  }, [
    location.pathname,
    IS_DASHBOARD_ROUTE,
    IS_ASSESSMENT_ROUTE,
    IS_ASSIGNMENT_ROUTE,
    IS_EXAMINATION_ROUTE,
    IS_LESSONS_ROUTE,
  ]);
  return (
    <div className="hidden lg:flex w-full flex-wrap  py-5 sm:flex-row justify-between ">
      <Link to={`/dashboard`} className="py-2">
        <div
          className={`${
            IS_DASHBOARD_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
          } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
        >
          <InlineSVG
            src={HomeSVG}
            preProcessor={(code) =>
              code.replace(
                /fill="[^"]*"/g,
                `fill=${IS_DASHBOARD_ROUTE ? "#EAE7E7" : "#006B5D"}`
              )
            }
            className={`mx-auto ${
              IS_DASHBOARD_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
            }`}
          />

          <Typography
            style={{ color: `${IS_DASHBOARD_ROUTE ? "#EAE7E7" : "#006B5D"}` }}
            {...{
              className: "text-center font-[600] text-[14px] leading-[16.94px]",
            }}
          >
            Dashboard
          </Typography>
        </div>
      </Link>

      {assessmentCompleted ? (
        <Link to={LESSONS_ROUTE} className="py-2">
          <div
            className={`${
              IS_LESSONS_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
            } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
          >
            <InlineSVG
              src={LessonSVG}
              preProcessor={(code) =>
                code.replace(
                  /fill="[^"]*"/g,
                  `fill=${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}`
                )
              }
              className={`mx-auto ${
                IS_LESSONS_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
              }`}
            />
            <Typography
              style={{ color: `${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}` }}
              {...{
                className:
                  "text-center font-[600] text-[14px] leading-[16.94px]",
              }}
            >
              Lessons
            </Typography>
          </div>
        </Link>
      ) : (
        <>
          {user.role === "Teacher" || user.role === "Admin" ? (
            <Link to={LESSONS_ROUTE} className="py-2">
              <div
                className={`${
                  IS_LESSONS_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
              >
                <InlineSVG
                  src={LessonSVG}
                  preProcessor={(code) =>
                    code.replace(
                      /fill="[^"]*"/g,
                      `fill=${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}`
                    )
                  }
                  className={`mx-auto ${
                    IS_LESSONS_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                  }`}
                />
                <Typography
                  style={{
                    color: `${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                  }}
                  {...{
                    className:
                      "text-center font-[600] text-[14px] leading-[16.94px]",
                  }}
                >
                  Lessons
                </Typography>
              </div>
            </Link>
          ) : (
            <>
              <div className="py-2 opacity-50 cursor-not-allowed">
                <div
                  className={`${
                    IS_LESSONS_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                  } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
                >
                  <InlineSVG
                    src={LessonSVG}
                    preProcessor={(code) =>
                      code.replace(
                        /fill="[^"]*"/g,
                        `fill=${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      )
                    }
                    className={`mx-auto ${
                      IS_LESSONS_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                    }`}
                  />
                  <Typography
                    style={{
                      color: `${IS_LESSONS_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                    }}
                    {...{
                      className:
                        "text-center font-[600] text-[14px] leading-[16.94px]",
                    }}
                  >
                    Lessons
                  </Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {assessmentCompleted ? (
        <Link to={ASSIGNMENT_ROUTE} className="py-2">
          <div
            className={`${
              IS_ASSIGNMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
            } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
          >
            <InlineSVG
              src={AssignmentSVG}
              preProcessor={(code) =>
                code.replace(
                  /fill="[^"]*"/g,
                  `fill=${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                )
              }
              className={`mx-auto ${
                IS_ASSIGNMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
              }`}
            />

            <Typography
              style={{
                color: `${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
              }}
              {...{
                className:
                  "text-center font-[600] text-[14px] leading-[16.94px]",
              }}
            >
              Assignments
            </Typography>
          </div>
        </Link>
      ) : (
        <>
          {user.role === "Teacher" || user.role === "Admin" ? (
            <Link to={ASSIGNMENT_ROUTE} className="py-2">
              <div
                className={`${
                  IS_ASSIGNMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
              >
                <InlineSVG
                  src={AssignmentSVG}
                  preProcessor={(code) =>
                    code.replace(
                      /fill="[^"]*"/g,
                      `fill=${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                    )
                  }
                  className={`mx-auto ${
                    IS_ASSIGNMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                  }`}
                />

                <Typography
                  style={{
                    color: `${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                  }}
                  {...{
                    className:
                      "text-center font-[600] text-[14px] leading-[16.94px]",
                  }}
                >
                  Assignments
                </Typography>
              </div>
            </Link>
          ) : (
            <>
              <div className="py-2 opacity-50 cursor-not-allowed">
                <div
                  className={`${
                    IS_ASSIGNMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                  } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
                >
                  <InlineSVG
                    src={AssignmentSVG}
                    preProcessor={(code) =>
                      code.replace(
                        /fill="[^"]*"/g,
                        `fill=${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      )
                    }
                    className={`mx-auto ${
                      IS_ASSIGNMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                    }`}
                  />

                  <Typography
                    style={{
                      color: `${IS_ASSIGNMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                    }}
                    {...{
                      className:
                        "text-center font-[600] text-[14px] leading-[16.94px]",
                    }}
                  >
                    Assignments
                  </Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {assessmentCompleted ? (
        <Link to={ASSESSMENT_ROUTE} className="py-2">
          <div
            className={`${
              IS_ASSESSMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
            } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
          >
            <InlineSVG
              src={AssessmentSVG}
              preProcessor={(code) => {
                code.replace(
                  /fill="[^"]*"/g,
                  `fill=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
                return code.replace(
                  /stroke="[^"]*"/g,
                  `stroke=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
              }}
              className={`mx-auto ${
                IS_ASSESSMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
              }`}
            />
            <Typography
              style={{
                color: `${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
              }}
              {...{
                className:
                  "text-center font-[600] text-[14px] leading-[16.94px]",
              }}
            >
              Assessments
            </Typography>
          </div>
        </Link>
      ) : (
        <>
          {user.role === "Teacher" || user.role === "Admin" ? (
            <Link to={ASSESSMENT_ROUTE} className="py-2">
              <div
                className={`${
                  IS_ASSESSMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
              >
                <InlineSVG
                  src={AssessmentSVG}
                  preProcessor={(code) => {
                    code.replace(
                      /fill="[^"]*"/g,
                      `fill=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                    );
                    return code.replace(
                      /stroke="[^"]*"/g,
                      `stroke=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                    );
                  }}
                  className={`mx-auto ${
                    IS_ASSESSMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                  }`}
                />
                <Typography
                  style={{
                    color: `${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                  }}
                  {...{
                    className:
                      "text-center font-[600] text-[14px] leading-[16.94px]",
                  }}
                >
                  Assessments
                </Typography>
              </div>
            </Link>
          ) : (
            <>
              <div className="py-2 opacity-50 cursor-not-allowed">
                <div
                  className={`${
                    IS_ASSESSMENT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                  } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
                >
                  <InlineSVG
                    src={AssessmentSVG}
                    preProcessor={(code) => {
                      code.replace(
                        /fill="[^"]*"/g,
                        `fill=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      );
                      return code.replace(
                        /stroke="[^"]*"/g,
                        `stroke=${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      );
                    }}
                    className={`mx-auto ${
                      IS_ASSESSMENT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                    }`}
                  />
                  <Typography
                    style={{
                      color: `${IS_ASSESSMENT_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                    }}
                    {...{
                      className:
                        "text-center font-[600] text-[14px] leading-[16.94px]",
                    }}
                  >
                    Assessments
                  </Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {assessmentCompleted ? (
        <Link to={EXAMINATION_ROUTE} className="py-2">
          <div
            className={`${
              IS_EXAMINATION_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
            } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
          >
            <Typography
              style={{
                color: `${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`,
              }}
              {...{
                className:
                  "text-center font-[600] text-[14px] leading-[16.94px]",
              }}
            >
              <InlineSVG
                src={ExaminationSVG}
                preProcessor={(code) => {
                  code.replace(
                    /fill="[^"]*"/g,
                    `fill=${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`
                  );
                  return code.replace(
                    /stroke="[^"]*"/g,
                    `stroke=${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`
                  );
                }}
                className={`mx-auto ${
                  IS_EXAMINATION_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                }`}
              />
              Examination
            </Typography>
          </div>
        </Link>
      ) : (
        <>
          {user.role === "Teacher" || user.role === "Admin" ? (
            <Link to={EXAMINATION_ROUTE} className="py-2">
              <div
                className={`${
                  IS_EXAMINATION_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
              >
                <Typography
                  style={{
                    color: `${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                  }}
                  {...{
                    className:
                      "text-center font-[600] text-[14px] leading-[16.94px]",
                  }}
                >
                  <InlineSVG
                    src={ExaminationSVG}
                    preProcessor={(code) => {
                      code.replace(
                        /fill="[^"]*"/g,
                        `fill=${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      );
                      return code.replace(
                        /stroke="[^"]*"/g,
                        `stroke=${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`
                      );
                    }}
                    className={`mx-auto ${
                      IS_EXAMINATION_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
                    }`}
                  />
                  Examination
                </Typography>
              </div>
            </Link>
          ) : (
            <>
              <div className="py-2 opacity-50 cursor-not-allowed">
                <div
                  className={`${
                    IS_EXAMINATION_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
                  } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
                >
                  <Typography
                    style={{
                      color: `${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`,
                    }}
                    {...{
                      className:
                        "text-center font-[600] text-[14px] leading-[16.94px]",
                    }}
                  >
                    <InlineSVG
                      src={ExaminationSVG}
                      preProcessor={(code) => {
                        code.replace(
                          /fill="[^"]*"/g,
                          `fill=${IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"}`
                        );
                        return code.replace(
                          /stroke="[^"]*"/g,
                          `stroke=${
                            IS_EXAMINATION_ROUTE ? "#EAE7E7" : "#006B5D"
                          }`
                        );
                      }}
                      className={`mx-auto ${
                        IS_EXAMINATION_ROUTE
                          ? "text-[#006B5D]"
                          : "text-[#EAE7E7]"
                      }`}
                    />
                    Examination
                  </Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Link to={"#"} className="cursor-not-allowed py-2">
        <div
          className={`${
            IS_RESULT_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
          } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
        >
          <Typography
            style={{ color: `${IS_RESULT_ROUTE ? "#EAE7E7" : "#006B5D"}` }}
            {...{
              className: "text-center font-[600] text-[14px] leading-[16.94px]",
            }}
          >
            <InlineSVG
              src={ResultSVG}
              preProcessor={(code) => {
                code.replace(
                  /fill="[^"]*"/g,
                  `fill=${IS_RESULT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
                return code.replace(
                  /stroke="[^"]*"/g,
                  `stroke=${IS_RESULT_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
              }}
              className={`mx-auto ${
                IS_RESULT_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
              }`}
            />
            Results
          </Typography>
        </div>
      </Link>
      <Link to={"#"} className="cursor-not-allowed py-2">
        <div
          className={`${
            IS_MESSAGE_INBOX_ROUTE ? "bg-[#006B5D]" : "bg-[#EAE7E7]"
          } border-2 border-white rounded-lg flex flex-col h-[177px] w-[158px] sm:w-[118px] lg:w-[158px] space-y-3 justify-center px-2`}
        >
          <Typography
            style={{
              color: `${IS_MESSAGE_INBOX_ROUTE ? "#EAE7E7" : "#006B5D"}`,
            }}
            {...{
              className: "text-center font-[600] text-[14px] leading-[16.94px]",
            }}
          >
            <InlineSVG
              src={MessagesSVG}
              preProcessor={(code) => {
                code.replace(
                  /fill="[^"]*"/g,
                  `fill=${IS_MESSAGE_INBOX_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
                return code.replace(
                  /stroke="[^"]*"/g,
                  `stroke=${IS_MESSAGE_INBOX_ROUTE ? "#EAE7E7" : "#006B5D"}`
                );
              }}
              className={`mx-auto ${
                IS_MESSAGE_INBOX_ROUTE ? "text-[#006B5D]" : "text-[#EAE7E7]"
              }`}
            />
            Messages
          </Typography>
        </div>
      </Link>
    </div>
  );
}

export default MenuIcons;
